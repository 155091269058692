
import { defineComponent } from 'vue';
import UndefinedResourceError from '@/types/exceptions/UndefinedResourceError';
import SegmentDataHelper from '@/helpers/SegmentDataHelper';
import globals from '../../helpers/globals';
import FinanceTypeDataHelper from '../../helpers/FinanceTypeDataHelper';
import FinanceType from '../../types/FinanceType';
import PrimaryButton from '../../components/PrimaryButton.vue';
import StepData from '../../types/StepData';

export default defineComponent({
  name: 'FinanceTypeSelection',
  mixins: [
    globals,
  ],
  components: {
    PrimaryButton,
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSegment', SegmentDataHelper.getObjectFromStore(vm.$store, vm.$route.params.segment as string));

      if (Object.keys(vm.$store.state.selected.segment).length === 0) {
        throw new UndefinedResourceError();
      }

      vm.$store.dispatch('setDataIntoStep', { step: 'IsCustomerSelection', data: { isCustomer: localStorage.isCustomer } } as StepData);

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);
      this.$store.dispatch('toggleTopBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      FinanceTypeDataHelper.prepareComponentData(
        this.$store,
        this.$route.params.segment as string,
        callback,
      );
    },
    setSelectedFinanceTypeInStore(target: FinanceType) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      this.setStepAsCompleted(this, true);

      localStorage.financeType = target.pid;

      this.goToNextScreen(this, {
        segment: this.$store.state.selected.segment.pid,
        financeType: target.pid,
      });
    },
    isSelected(pid: string) {
      return localStorage.financeType && localStorage.financeType === pid;
    },
  },
});
